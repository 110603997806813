import moment from 'moment';
import React, { Fragment, useContext, useRef } from 'react';
import {
   ORDER_STATUS_SHOW,
   PAYMENT_METHOD_SHOW,
   PAYMENT_STATUS_SHOW,
   SHIP_METHOD_SHOW,
   SHIP_STATUS_SHOW,
   TIME_ZONE
} from '../../../../constants';
import DataTabel from '../../../../reuseable/DataTabel';
import { TABEL_UNIQUE_ID } from '../../../../constants/RoutingConstants/RoutingConstants';
import OrderContext from '../../../../context/OrderContext/OrderContext';
import { IconButton, Tooltip } from '@mui/material';
import { Eye, Printer } from 'react-feather';
import { Link } from 'react-router-dom';
import { REDIRECT_PATH } from '../../../../Routes';
import { useMutation } from 'react-query';
import { get_Order_Detail, get_Order_Excel } from '../../../../Apis/orderApi/OrderApi';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import JsBarcode from 'jsbarcode';
import { Invoice } from '../ecommerce/Invoice';
import { AssignmentTurnedIn } from '@mui/icons-material';

const OrderDataTabelContainer = (props) => {
   const {
      filter,
      setFilter,
      record,
      pageSize,
      page,
      setPageSize,
      setPage,
      setSortBy,
      setSortOrder,
      sortOrder,
      setdialogs,
      setFormData
   } = useContext(OrderContext);
   const { isLoading, data, dates, setDates } = props;
   const barcodeRef = useRef(null);

   /**
    *
    * @param {Sorting obj} col
    * Table Sorting
    */

   const sorting = (col) => {
      if (sortOrder === '') {
         let found = col.find((obj) => {
            return obj.field;
         });
         setSortBy(found.field);
         setSortOrder('ASC');
      }
      if (sortOrder === 'ASC') {
         let found = col.find((obj) => {
            return obj.field;
         });
         setSortBy(found.field);
         setSortOrder('DSC');
      }
      if (sortOrder === 'DSC') {
         setSortBy();
         setSortOrder('');
      }
   };

   const handleOpenandClosePopup = () => {};

   const { mutate: downloadExcel } = useMutation((formData) => get_Order_Excel(formData));

   const handleExport = () => {
      let formData = {};
      if (dates[0] && dates[1] && dates?.length === 2 && dates) {
         formData['search'] = {
            startDate: moment(dates[0]).format(),
            endDate: moment(dates[1]).format()
         };
      }
      downloadExcel(formData);
   };

   const handleOrderId = ({ orderId }) => {
      setdialogs(true);
      setFormData((prev) => {
         return {
            ...prev,
            orderId
         };
      });
   };

   const { mutate: createOrder, isLoading: orderLoader } = useMutation(
      (orderId) =>
         get_Order_Detail({
            orderId
         }),
      {
         onSuccess: (response) => {
            console.log('response >>', response);
            if (response && Object.keys(response).length) {
               if (barcodeRef.current) {
                  JsBarcode(barcodeRef.current, response.orderId, {
                     format: 'CODE128',
                     lineColor: '#000',
                     width: 2,
                     height: 50,
                     displayValue: true
                  });
                  const printContent = barcodeRef.current.outerHTML;
                  document.getElementById('donationReceipt').innerHTML = `${Invoice(response, printContent)}`;
                  window.print();
               }
            }
         }
      }
   );

   const defaultColumns = [
      {
         flex: 0.05,
         minWidth: 100,
         field: 'number',
         headerName: '#',
         renderCell: (params) => (params.row.orderId ? params.row.orderId : '--')
      },
      {
         flex: 0.1,
         minWidth: 120,
         field: 'total',
         headerName: 'Total',
         renderCell: (params) => (params.row.grandTotal ? params.row.grandTotal : '--')
      },
      {
         flex: 0.1,
         minWidth: 100,
         field: 'status',
         headerName: 'Status',
         renderCell: (params) => (params.row.orderStatus ? ORDER_STATUS_SHOW[params.row.orderStatus] : '--')
      },
      {
         flex: 0.1,
         minWidth: 100,
         field: 'method',
         headerName: 'Payment Method',
         renderCell: (params) => (params.row.paymentMethod ? PAYMENT_METHOD_SHOW[params.row.paymentMethod] : '--')
      },
      {
         flex: 0.1,
         minWidth: 100,
         field: 'paymentStatus',
         headerName: 'Payment Status',
         renderCell: (params) => (params.row.paymentStatus ? PAYMENT_STATUS_SHOW[params.row.paymentStatus] : '--')
      },
      {
         flex: 0.1,
         minWidth: 100,
         field: 'shippingMethod',
         headerName: 'Shipping Method',
         renderCell: (params) => (params.row.paymentMethod ? SHIP_METHOD_SHOW[params.row.shipMethod] : '--')
      },
      {
         flex: 0.1,
         minWidth: 100,
         field: 'shippingStatus',
         headerName: 'Shipping Status',
         renderCell: (params) =>
            params.row.paymentStatus ? (
               <p style={{ whiteSpace: 'normal' }}>{SHIP_STATUS_SHOW[params.row.shipStatus]}</p>
            ) : (
               '--'
            )
      },
      {
         flex: 0.1,
         minWidth: 180,
         field: 'createdAt',
         headerName: 'CreatedAt',
         sortable: false,
         renderCell: (params) => moment(params.row.orderDate).utcOffset(TIME_ZONE).format('DD MMM, YYYY hh:mm')
      },
      {
         flex: 0.1,
         minWidth: 100,
         field: 'actions',
         headerName: 'Actions',
         sortable: false,

         renderCell: (params) => (
            <>
               <div style={{ paddingRight: '5px' }}>
                  <Tooltip title='Print'>
                     <IconButton aria-label='edit' onClick={() => createOrder(params.row.orderId)}>
                        <Printer style={{ color: '#7366ff' }} />
                     </IconButton>
                  </Tooltip>
               </div>
               <div style={{ paddingRight: '5px' }}>
                  <Tooltip title='Order Detail'>
                     <Link to={`${REDIRECT_PATH.ORDERS}/detail/${params.row.orderId}`}>
                        <IconButton aria-label='edit'>
                           <Eye style={{ color: '#7366ff' }} />
                        </IconButton>
                     </Link>
                  </Tooltip>
               </div>
            </>
         )
      }
   ];

   const removeFilter = () => {
      setDates(null);
   };

   const toolBar = {
      isTool: true,
      startContent: (
         <React.Fragment>
            <Calendar
               value={dates}
               onChange={(e) => setDates(e.value)}
               selectionMode='range'
               readOnlyInput
               placeholder='Start Date - End Date'
               showIcon
               showButtonBar
               tooltip='Excel Shit Start Date & End Date Filter'
               tooltipOptions={{ position: 'right', event: 'both' }}
               className='mr-2'
            />
            <Button
               icon='pi pi-undo'
               className='p-button-danger mr-2'
               onClick={removeFilter}
               tooltip='Remove Filter'
               tooltipOptions={{ position: 'top' }}
            />
         </React.Fragment>
      ),
      endContent: (
         <React.Fragment>
            <Button
               icon='pi pi-file-export'
               className='p-button-help'
               onClick={handleExport}
               tooltip='Export'
               tooltipOptions={{ position: 'top' }}
            />
         </React.Fragment>
      )
   };
   return (
      <Fragment>
         <div className='d-none'>
            <svg ref={barcodeRef}></svg>
         </div>
         <div id='donationReceipt'></div>
         <DataTabel
            filter={filter}
            setFilter={setFilter}
            handleOpenandClosePopup={handleOpenandClosePopup}
            data={data}
            defaultColumns={defaultColumns}
            record={record}
            pageSize={pageSize}
            page={page}
            setPageSize={setPageSize}
            setPage={setPage}
            sorting={sorting}
            isLoading={isLoading}
            sortOrder={sortOrder}
            setSortBy={setSortBy}
            setSortOrder={setSortOrder}
            id={TABEL_UNIQUE_ID.orderId}
            addButton={false}
            search={false}
            toolBar={toolBar}
         />
      </Fragment>
   );
};

export default OrderDataTabelContainer;

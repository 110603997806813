import React, { useContext, useEffect, useRef, useState } from 'react';
import CartList from './Checkout/CartList';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { useMutation, useQuery } from 'react-query';
import { create_Order } from '../../../../Apis/POSApis/POSApi';
import { IsLoadingResponse, ToastMessage } from '../../../../utils/Helper';
import { get_User } from '../../../../Apis/userApi/UserApi';
import { Role, TYPE_FELDS } from '../../../../constants';
import { FormDataFeild } from '../../../../reuseable/Inputs';
import { Invoice } from './Invoice';
import UserCreateFormPopup from '../UserModule/UserCreateFormPopup';
import UserModuleContext from '../../../../context/UserModuleContext/UserModuleContext';
import JsBarcode from 'jsbarcode';

const Checkout = ({
   cartData,
   createOrderCart,
   deleteOrderCart,
   createOrderLoader,
   cartRefetch,
   userId,
   setUserId
}) => {
   const [formData, setFormData] = useState({ paymentMethod: '1', shipMethod: '1', discAmt: '', paidAmt: '' });
   const [filterDrop, setFilterDrop] = useState({
      name: ''
   });
   const { dialog, setFormData: setUserFormData, setdialogs, setErrors, setIsEdit } = useContext(UserModuleContext);
   const [isClearCart, setIsClearCart] = useState(false);
   const barcodeRef = useRef(null);

   const emptyData = () => {
      setUserFormData({
         name: null,
         phone: null,
         dialCode: { code: '+44', name: '+44' },
         email: null,
         pincode: null,
         address: null,
         landmark: null,
         city: null,
         blockNo: null
      });
   };

   /**
    * Open Popup and Close Popup User
    */
   const handleOpenandClosePopup = (condition) => {
      setIsEdit(true);
      if (condition) {
         setdialogs(condition);
         emptyData();
         setErrors({});
      } else {
         setdialogs(condition);
         emptyData();
         setErrors({});
      }
   };

   /**
    * create order
    */
   const { mutate: createOrder, isLoading: orderLoader } = useMutation(
      () =>
         create_Order({
            formData: { ...formData, userId: userId }
         }),
      {
         onSuccess: (response) => {
            if (response.status === 200) {
               cartRefetch();
               if (barcodeRef.current) {
                  JsBarcode(barcodeRef.current, response.data.payload.data.orderId, {
                     format: 'CODE128',
                     lineColor: '#000',
                     width: 2,
                     height: 50,
                     displayValue: true
                  });
                  const printContent = barcodeRef.current.outerHTML;
                  document.getElementById('donationReceipt').innerHTML = `${Invoice(
                     response.data.payload.data,
                     printContent
                  )}`;
                  window.print();
               }
               setFormData({ paymentMethod: '1', shipMethod: '1', discAmt: '', paidAmt: '' });
            }
         }
      }
   );

   let paramsUserObj = {
      showAll: true,
      search: {
         name: filterDrop.name,
         roleId: [Role.Sales]
      }
   };

   let { data, refetch } = useQuery(['getUserPOSDropdown', paramsUserObj], async () => await get_User(paramsUserObj), {
      keepPreviousData: true,
      refetchOnWindowFocus: false
   });

   const handleChangeCategory = (e) => {
      setFilterDrop((prev) => {
         return {
            ...prev,
            userId: e.target.value
         };
      });
      if (e.target.name === 'customer') {
         setUserId(e.target.value);
      }
   };

   useEffect(() => {
      if (data?.payload?.data?.length > 0) {
         setFilterDrop((prev) => {
            return {
               ...prev,
               userId: data?.payload?.data[0]?.userId
            };
         });
         setUserId(data?.payload?.data[0]?.userId);
      }
   }, [data?.payload?.data]);

   const clearCart = () => {
      try {
         setIsClearCart(true);
         for (const iterator of cartData?.data?.payload?.data) {
            deleteOrderCart({ cartId: iterator?.cartId });
         }
         setIsClearCart(false);
      } catch (error) {
      } finally {
         setIsClearCart(false);
      }
   };

   return (
      <>
         <IsLoadingResponse isLoading={orderLoader || isClearCart} />
         <div className='d-none'>
            <svg ref={barcodeRef}></svg>
         </div>
         <div id='donationReceipt'></div>
         <div className='tabel-contant'>
            <div className={`tabelContent`}>
               <div
                  className='tabelSearch border-bottom m-0 position-sticky'
                  style={{ padding: '20px 42px 20px 42px', top: '95px', background: 'white', zIndex: '99' }}>
                  <div className='text-900 font-medium text-2xl mb-4'>
                     Billing Section
                     <div className='d-flex align-items-end gap-2 flex-wrap'>
                        <div style={{ width: '88%' }}>
                           <FormDataFeild
                              label={''}
                              name='customer'
                              id='customer'
                              placeholder={'All Customer'}
                              value={filterDrop.userId}
                              handleChange={handleChangeCategory}
                              dropdown={true}
                              option={data?.payload?.data?.map((elem) => {
                                 return {
                                    value: elem?.userId,
                                    name: elem?.name
                                 };
                              })}
                              search={(e) =>
                                 setFilterDrop((prev) => {
                                    return {
                                       ...prev,
                                       name: e.filter
                                    };
                                 })
                              }
                           />
                        </div>
                        <div style={{ paddingBottom: '4px', width: '10%' }}>
                           <Button
                              icon='pi pi-plus'
                              severity='warning'
                              style={{ height: '40px', width: '100%' }}
                              onClick={handleOpenandClosePopup}
                              tooltip='Add New Customer'
                              tooltipOptions={{ position: 'bottom' }}
                           />
                        </div>
                     </div>
                  </div>
               </div>
               <div
                  className='px-3'
                  style={{
                     maxHeight: '40rem',
                     overflow: 'hidden',
                     overflowY: 'scroll'
                  }}>
                  {cartData?.data?.payload?.data?.map((elem) => {
                     return (
                        <CartList
                           key={elem.cartId}
                           elem={elem}
                           createOrderCart={createOrderCart}
                           deleteOrderCart={deleteOrderCart}
                           createOrderLoader={createOrderLoader}
                           userId={userId}
                        />
                     );
                  })}
                  <div class='py-2 mt-3'>
                     <div class='flex justify-content-between align-items-center mb-3'>
                        <span class='text-900 font-bold'>Sub Total</span>
                        <span class='text-900 font-medium text-xl'>£{cartData?.data?.payload?.totalCartValue}</span>
                     </div>
                     <div class='flex justify-content-between align-items-center mb-3'>
                        <span class='text-900 font-bold'>Discount Amount</span>
                        <span class='text-900 font-medium text-xl'>
                           <div style={{ maxWidth: '120px' }}>
                              <FormDataFeild
                                 label={''}
                                 name='discAmt'
                                 id='discAmt'
                                 placeholder={''}
                                 value={formData.discAmt}
                                 handleChange={(e) => {
                                    setFormData((prev) => ({
                                       ...prev,
                                       discAmt: e.target.value,
                                       paidAmt: (cartData?.data?.payload?.totalCartValue - e.target.value).toFixed(2)
                                    }));
                                 }}
                                 type={TYPE_FELDS.number}
                                 max={cartData?.data?.payload?.totalCartValue}
                              />
                           </div>
                        </span>
                     </div>
                     <div class='flex justify-content-between align-items-center mb-3'>
                        <span class='text-900 font-bold'>Grand Total</span>
                        <span class='text-900 font-medium text-xl'>
                           £{(cartData?.data?.payload?.totalCartValue - formData.discAmt).toFixed(2)}
                        </span>
                     </div>
                     <div class='flex justify-content-between align-items-center mb-3'>
                        <span class='text-900 font-bold'>Paid Amount</span>
                        <span class='text-900 font-medium text-xl'>
                           <div style={{ maxWidth: '120px' }}>
                              <FormDataFeild
                                 label={''}
                                 name='paidAmt'
                                 id='paidAmt'
                                 placeholder={''}
                                 value={formData.paidAmt}
                                 handleChange={(e) =>
                                    setFormData((prev) => ({
                                       ...prev,
                                       paidAmt: e.target.value
                                    }))
                                 }
                                 type={TYPE_FELDS.number}
                                 max={cartData?.data?.payload?.totalCartValue - formData.discAmt}
                              />
                           </div>
                        </span>
                     </div>
                     <div class='flex justify-content-between align-items-center mb-3'>
                        <span class='text-900 font-bold'>Due Amount</span>
                        <span class='text-900 font-medium text-xl'>
                           £{(cartData?.data?.payload?.totalCartValue - formData.discAmt - formData.paidAmt).toFixed(2)}
                        </span>
                     </div>
                  </div>
                  <div class='py-2 mt-3'>
                     <div class='flex justify-content-between align-items-center mb-3 flex-wrap gap-2'>
                        <span class='text-900 font-bold'>Pay By</span>
                        <span class='text-900 font-medium text-xl'>
                           <div className='flex flex-wrap gap-3'>
                              <div className='flex align-items-center'>
                                 <RadioButton
                                    inputId='ingredient1'
                                    name='pizza'
                                    value='1'
                                    onChange={(e) =>
                                       setFormData((prev) => {
                                          return {
                                             ...prev,
                                             paymentMethod: e.target.value
                                          };
                                       })
                                    }
                                    checked={formData.paymentMethod == 1}
                                 />
                                 <label htmlFor='ingredient1' className='ml-2'>
                                    Cash
                                 </label>
                              </div>
                              <div className='flex align-items-center'>
                                 <RadioButton
                                    inputId='ingredient1'
                                    name='pizza'
                                    value='4'
                                    onChange={(e) =>
                                       setFormData((prev) => {
                                          return {
                                             ...prev,
                                             paymentMethod: e.target.value
                                          };
                                       })
                                    }
                                    checked={formData.paymentMethod == 4}
                                 />
                                 <label htmlFor='ingredient1' className='ml-2'>
                                    Card
                                 </label>
                              </div>
                           </div>
                        </span>
                     </div>
                  </div>
               </div>
               <div className='tabelSearch border-top d-flex justify-content-between pos-checkout-button  gap-2 pt-3'>
                  <Button
                     label='Cancel Order'
                     severity='danger'
                     style={{ height: '40px', width: '100%' }}
                     onClick={() => clearCart()}
                     disabled={!cartData?.data?.payload?.data?.length}
                  />
                  <Button
                     label='Place Order'
                     severity='success'
                     style={{ height: '40px', width: '100%' }}
                     onClick={() => {
                        if (!userId) {
                           ToastMessage(1, 'Please Select user after click to place order !');
                           return;
                        }
                        createOrder();
                     }}
                  />
               </div>
            </div>
         </div>
         {dialog && (
            <UserCreateFormPopup
               handleOpenandClosePopup={handleOpenandClosePopup}
               refetch={refetch}
               emptyData={emptyData}
               isPOS={true}
            />
         )}
      </>
   );
};

export default Checkout;
